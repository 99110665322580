<template>
    <div class="upload-paper">
        <h2>上传试卷</h2>
        <file-pond
            ref="pond"
            label-idle="拖拽文件至此 或 <span class='filepond--label-action'>浏览本地</span>"
            accepted-file-types="application/pdf"
            allow-multiple="false"
        />
        <el-button type="primary" v-if="!pdfPagesRendered" @click="openExamForm" style="width: 70px;">点击确认</el-button>

        <div v-if="totalPages > 0" class="navigation">
            <el-button @click="goToFirstPage" :disabled="currentPage === 1">首页</el-button>
            <el-button @click="goToPreviousPage" :disabled="currentPage === 1">上一页</el-button>
            <el-input-number v-model="currentPage" :min="1" :max="totalPages" @change="goToPage"></el-input-number>
            <span>/ {{ totalPages }}</span>
            <el-button @click="goToNextPage" :disabled="currentPage === totalPages">下一页</el-button>
            <el-button @click="goToLastPage" :disabled="currentPage === totalPages">末页</el-button>
            <el-button class="add-question-button" type="primary" @click="toggleCropPanel()">点击添加题目</el-button>
        </div>  
        <exam-form v-if="isExamFormVisible" :pdfFileName="pdfFileName" @submit-exam="handleExamSubmit" @close="closeExamForm"/>

        <div class="pdf-container">
            <canvas ref="canvas"></canvas>
            <el-button type="primary" v-if="isCropping" @click="confirmCroppedArea">确认裁剪</el-button>
            <add-question-panel
                :visible="isPanelVisible"
                :questions="croppedImages"
                @start-cropping="startCropping"
                @submit-question="submitQuestion"
                @close="closePanel"
            />
        </div>
        <div v-for="(page, index) in pdfPages" :key="index" class="pdf-page">
            <canvas :ref="'canvas' + index"></canvas>
        </div>
    </div>
</template>
  
<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import Tesseract from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import axios from 'axios';
import AddQuestionPanel from './AddQuestionPanel.vue';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import ExamForm from './ExamForm.vue';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import CSS styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
const FilePond = vueFilePond(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
);
  
export default {
    components: {
      FilePond,
      AddQuestionPanel,
      ExamForm
    },
    data() {
      return {
        pdfPages: [],
        totalPages: 0,
        currentPage: 1,
        croppedImages: [],
        cropper: null,
        currentPageIndex: null,
        isCropping: false,
        pdfPagesRendered: false,
        isPanelVisible: false,
        isExamFormVisible: false,
        pdfFileName: '',
        examId: '',
      };
    },
    methods: {
        async processFile() {
            const files = this.$refs.pond.getFiles();
            if (files.length) {
                await this.extractPagesFromPDF(files[0].file);  // Manually trigger processing
            }
        },

        openExamForm() {
            this.pdfFileName = this.$refs.pond.getFiles()[0].file.name.replace(/\.[^/.]+$/, '');
            this.isExamFormVisible = true;
        },

        closeExamForm() {
            this.isExamFormVisible = false;
            this.croppedImages = [];
        },

        async handleExamSubmit(examData) {
            try {
                const formData = new FormData();
                formData.append('title', examData.title);
                formData.append('duration', examData.duration);
                formData.append('grade', examData.grade);
                formData.append('course', examData.course);
                formData.append('content', this.$refs.pond.getFiles()[0].file);
                await axios.post(`${process.env.VUE_APP_API_URL}/exam/upload_papers`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.examId = response.data.examId;
                    this.$message.success('考试创建成功！现在可以添加题目');
                    this.isExamFormVisible = false;
                    this.processFile();
                }).catch(error => {
                    if (error.response && error.response.status === 409) {
                        this.$message.error('文件名已存在，请选择其他文件或重命名');
                    } else {
                        this.$message.error('考试创建失败');
                    }
                });
            } catch (error) {
                this.$message.error('考试信息提交失败');
                console.error(error);
            }
        },

        async extractPagesFromPDF(pdfFile) {
            const fileReader = new FileReader();
            fileReader.onload = async (event) => {
                const typedArray = new Uint8Array(event.target.result);
                this.pdf = await pdfjsLib.getDocument(typedArray).promise;
                this.totalPages = this.pdf.numPages;
                this.renderPage(this.currentPage);
                this.pdfPagesRendered = true;
            };
            fileReader.readAsArrayBuffer(pdfFile);
        },

        async renderPage(pageNumber) {
            const page = await this.pdf.getPage(pageNumber);
            const viewport = page.getViewport({ scale: 1.5 });

            const canvas = this.$refs.canvas;
            if (canvas) {
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                await page.render(renderContext).promise;
            } else {
                console.error(`Canvas not found`);
            }
        },

        startCropping() {
            if (this.cropper) {
                this.cropper.destroy();
            }
            const canvas = this.$refs.canvas;
            this.cropper = new Cropper(canvas, {
                aspectRatio: NaN,
                viewMode: 2,
                scalable: true,
                zoomable: true,
            });
            this.isCropping = true;
            this.isPanelVisible = false;
        },
        confirmCroppedArea() {
            if (this.cropper) {
                const croppedCanvas = this.cropper.getCroppedCanvas();
                const croppedImage = croppedCanvas.toDataURL('image/png');

                // Ask the user to choose between Text (OCR) or Image
                this.$confirm('请选择保存方式：图片或文字？', '保存方式', {
                    confirmButtonText: '文字 (OCR)',
                    cancelButtonText: '图片',
                    type: 'warning',
                }).then(async () => {
                    // User chose Text (OCR)
                    const { data: { text } } = await Tesseract.recognize(croppedImage, 'eng');

                    // Ask if it's a question or an answer
                    this.$confirm('请选择保存为题目或答案？', '保存类型', {
                        confirmButtonText: '答案',
                        cancelButtonText: '题目',
                        type: 'warning',
                    }).then(() => {
                        // Save as Answer
                        this.croppedImages.push({
                            src: croppedImage,
                            type: 'Text',
                            text: text,
                            role: 'Answer',
                        });
                        this.$message.success('已成功识别文字并保存为答案');
                    }).catch(() => {
                        // Save as Question
                        this.croppedImages.push({
                            src: croppedImage,
                            type: 'Text',
                            text: text,
                            role: 'Question',
                        });
                        this.$message.success('已成功识别文字并保存为题目');
                    });
                }).catch(() => {
                    // User chose Image
                    this.$confirm('请选择保存为题目或答案？', '保存类型', {
                        confirmButtonText: '答案',
                        cancelButtonText: '题目',
                        type: 'warning',
                    }).then(() => {
                        // Save as Answer
                        this.croppedImages.push({
                            src: croppedImage,
                            type: 'Image',
                            text: '',
                            role: 'Answer',
                        });
                        this.$message.success('已成功保存为图片答案');
                    }).catch(() => {
                        // Save as Question
                        this.croppedImages.push({
                            src: croppedImage,
                            type: 'Image',
                            text: '',
                            role: 'Question',
                        });
                        this.$message.success('已成功保存为图片题目');
                    });
                });

                this.cropper.destroy(); // Destroy cropper after cropping
                this.cropper = null;
                this.isCropping = false;
                this.isPanelVisible = true;
            }
        },

        async submitQuestion(details) {
            const questionContent = [];
            const answerContent = [];

            for (const crop of this.croppedImages) {
                if (crop.role === 'Question') {
                    questionContent.push({
                        type: crop.type,
                        content: crop.type === 'Text' ? crop.text : crop.src,
                    });
                } else if (crop.role === 'Answer') {
                    answerContent.push({
                        type: crop.type,
                        content: crop.type === 'Text' ? crop.text : crop.src,
                    });
                }
            }

            const formData = new FormData();
            formData.append('examId', this.examId);
            formData.append('sort_num', details.sort_num);
            formData.append('score', parseInt(details.marks, 10));
            formData.append('type', parseInt(details.type, 10));
            formData.append('questionContent', JSON.stringify(questionContent));
            formData.append('answerContent', JSON.stringify(answerContent));
                
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/exam/upload_questions`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                this.$message.success('问题提交成功!');
                this.croppedImages = [];
            } catch (error) {
                this.$message.error('问题提交失败');
                console.error(error);
            }
        },

        goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.renderPage(pageNumber);
            }
            if (this.cropper) {
                this.cropper.destroy();
                this.cropper = null;
                this.isCropping = false;
            }
        },

        goToFirstPage() {
            this.goToPage(1);
        },

        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.goToPage(this.currentPage - 1);
            }
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.goToPage(this.currentPage + 1);
            }
        },

        goToLastPage() {
            this.goToPage(this.totalPages);
        },
        toggleCropPanel() {
            this.isPanelVisible = !this.isPanelVisible;
        },
        closePanel() {
            this.isPanelVisible = false;
            this.croppedImages = [];
        },
    }
  };
</script>
  
<style scoped>
.upload-paper {
    padding: 20px;
}
  
.pdf-container {
    margin-top: 20px;
    display: flex;
}
  
.pdf-page {
    margin-bottom: 20px;
}
  
.pdf-page canvas {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}
  
.crop-preview {
    margin-bottom: 20px;
}
  
.crop-preview img {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}
  
.crop-preview .el-radio-group {
    margin-top: 10px;
}
</style>
  