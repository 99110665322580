<template>
    <el-container style="height: 100vh;">
      <el-aside width="170px" class="sidebar-container">
        <div class="sidebar-title">学生面板</div>
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1" @click="navigateTo('')">
            <i class="el-icon-document"></i>
            <span>首页</span>
          </el-menu-item>
          <el-menu-item index="2" @click="navigateTo('download')">
            <i class="el-icon-document"></i>
            <span>试卷中心</span>
          </el-menu-item>
          <el-menu-item index="3" @click="navigateTo('practice')">
            <i class="el-icon-s-data"></i>
            <span>题目练习</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-s-data"></i>
            <span>错题本</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
    
    </el-container>
  </template>
  
  <script>
  export default {
    name: 'StudentDashboard',
    methods: {
      navigateTo(route) {
        this.$router.push({ path: `/${route}` });
      }
    }
  };
  </script>
  
  <style scoped>
  .el-header {
    background-color: #e0e0e0;
    color: #333;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .el-aside {
    background-color: #f0f0f0;
    padding: 0; /* Remove padding */
  }
  
  .sidebar-container {
    height: 100%;
  }
  
  .sidebar-title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    padding: 16px;
    text-align: center;
    background-color: #333;
  }
  
  .el-menu-vertical-demo {
    height: calc(100% - 50px); /* Adjust height based on title */
    width: 200px;
    border-right: none; /* Remove right border if any */
  }
</style>
  