<template>
    <div class="exam-form">
      <h2>考试信息</h2>
      <el-form ref="examForm" :model="examForm" label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="examForm.title"></el-input>
        </el-form-item>
        <el-form-item label="考试时长">
          <el-input-number v-model="examForm.duration" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="年级">
          <el-input-number v-model="examForm.grade" :min="1" :max="12"></el-input-number>
        </el-form-item>
        <el-form-item label="课程">
          <el-select v-model="examForm.course" placeholder="选择课程">
            <el-option label="英文" value="1"></el-option>
            <el-option label="数学" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="submitExam">提交</el-button>
        <el-button @click="$emit('close')">取消</el-button>
      </el-form>
    </div>
</template>
  
<script>
  export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        pdfFileName: {
            type: String,
            default: ''
        }
    },
    data() {
      return {
        examForm: {
          title:  this.pdfFileName,
          duration: 60,
          grade: 1,
          course: '',
        },
      };
    },
    methods: {
      submitExam() {
        if (!this.examForm.title || !this.examForm.duration || !this.examForm.course || !this.examForm.grade) {
            this.$message.error('所有必填项都必须填写');
            return;
        }
        this.$emit('submit-exam', this.examForm);
        this.$emit('close');
      },
    },
  };
</script>
  
<style scoped>
  .exam-form {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 50%;
    margin: 0 auto;
  }
</style>
  