import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/LoginPage.vue';
import HomePage from '../components/HomePage.vue';
import StudentDashboard from '../components/StudentDashboard.vue';
import TeacherDashboard from '../components/TeacherDashboard.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import ManageUsers from '../components/ManageUsers.vue';
import RegisterAcct from '../components/RegisterAcct.vue';
import ProfileView from '@/components/ProfileView.vue';
import UploadPapers from '@/components/UploadPapers.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: LoginPage },
  { path: '/register', component: RegisterAcct },
  { path: '/profile', component: ProfileView, meta: { requiresAuth: true} },
  { path: '/student-dashboard', component: StudentDashboard, meta: { requiresAuth: true, role: [0] }},
  { path: '/teacher-dashboard', component: TeacherDashboard, meta: { requiresAuth: true, role: [1] }},
  { path: '/admin-dashboard', component: AdminDashboard, meta: { requiresAuth: true, role: [9] }},
  { path: '/manage-users', component: ManageUsers, meta: { requiresAuth: true, role: [9] }},
  { path: '/upload-papers', component: UploadPapers, meta: { requiresAuth: true, role: [1, 9] }}, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const userRole = parseInt(localStorage.getItem('role'), 10);
  
  if ((to.path === '/' || to.path === '/login') && isAuthenticated) {
    switch (userRole) {
      case 0:
        next({ path: '/student-dashboard' });
        break;
      case 1:
        next({ path: '/teacher-dashboard' });
        break;
      case 9:
        next({ path: '/admin-dashboard' });
        break;
      default:
        next({ path: '/login' });
    }
  } else {
    const allowedRoles = to.meta.role || [];
    if (allowedRoles.length === 0 || allowedRoles.includes(userRole)) {
      next();
    } else {
      next('/'); 
    }
  }
});

export default router;
