<template>
  <el-container class="login-container">
    <el-main>
      <el-form @submit.prevent="handleLogin" :model="form" class="login-form" >
        <el-form-item label="账号">
          <el-input type="text" v-model="form.username" required></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="form.password" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">登录</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="goToRegister">注册</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="continueWithoutLogin">继续到主页面</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
          user_acct: this.form.username, 
          password: this.form.password
        });

        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('role', response.data.role);

        window.dispatchEvent(new Event('storage'));

        // Redirect based on user role
        if (response.data.role === 0) {
          console.log('Redirecting to student dashboard');
          this.$router.push({ path: '/student-dashboard' });
        } else if (response.data.role === 1) {
          this.$router.push({ path: '/teacher-dashboard' });
        } else if (response.data.role === 9) {
          this.$router.push({ path: '/admin-dashboard' });
        }
      } catch (error) {
        console.error('Login failed', error);
        if (error.response) {
          switch (error.response.status) {
            case 400:
              alert('Login failed: ' + (error.response.data || 'Bad Request. Please check your credentials.'));
              break;
            case 403:
              alert('Login failed: ' + (error.response.data || 'Your account is not active or pending approval.'));
              break;
            case 404:
              alert('Login failed: ' + (error.response.data || 'User not found.'));
              break;
            default:
              alert('Login failed: An unexpected error occurred.');
          }
        } else if (error.request) {
          alert('Login failed: No response from server. Please try again later.');
        } else {
          alert('Login failed: ' + error.message);
        }
      }
    },
    goToRegister() {
      this.$router.push({ path: '/register' });
    },
    continueWithoutLogin() {
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: auto; 
}

.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  width: 100%;
}
</style>

