<template>
  <div>
    <h2>首页</h2>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>
