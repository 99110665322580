import { reactive } from 'vue';

export const store = reactive({
  token: localStorage.getItem('token'),
  role: localStorage.getItem('role'),
  setToken(newToken) {
    this.token = newToken;
    if (newToken) {
      localStorage.setItem('token', newToken);
    } else {
      localStorage.removeItem('token');
    }
  },
  setRole(newRole) {
    this.role = newRole;
    if (newRole) {
      localStorage.setItem('role', newRole);
    } else {
      localStorage.removeItem('role');
    }
  },
});
