<template>
  <el-container>
    <el-header>
      <div class="header-content">
        <div class="title">你是傻逼</div>
        <el-dropdown @command="handleCommand" >
          <template #default>
            <span class="el-dropdown-link">
              <el-button class="login-button" type="primary">个人中心</el-button>
            </span>
          </template>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="isAuthenticated" command="profile">查看资料</el-dropdown-item>
              <el-dropdown-item :command="isAuthenticated ? 'logout' : 'login'">
                <span>{{ isAuthenticated ? '退出登录' : '登录' }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '../store';

export default {
  name: 'MainContent',
  setup() {
    const router = useRouter();

    const pageTitle = computed(() => {
      return router.currentRoute.value.meta.title || 'Main Dashboard';
    });

    const isAuthenticated = computed(() => !!store.token);

    const handleCommand = (command) => {
      if (command === 'profile') {
        router.push('/profile');
      } else if (command === 'logout') {
        logout();
      } else if (command === 'login') {
        router.push('/login');
      }
    };

    const logout = () => {
      store.setToken(null);
      store.setRole(null);
      router.push('/login');
    };

    window.addEventListener('storage', () => {
      store.setToken(localStorage.getItem('token'));
      store.setRole(localStorage.getItem('role'));
    });

    return {
      pageTitle,
      isAuthenticated,
      handleCommand,
    };
  },
};
</script>

<style scoped>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.login-button {
  margin-left: auto;
}
.el-main{
  padding: 0px;
  display: flex;
  flex-direction: column;
}
</style>
