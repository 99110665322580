<template>
    <div v-if="visible" class="add-question-panel">
        <div class="panel-header">
            <h3>添加题目</h3>
            <el-button type="primary" @click="startCropping">裁剪</el-button>
            <el-button type="success" @click="submitQuestion">提交</el-button>
            <el-button type="danger" @click="closePanel">关闭</el-button>
        </div>
        <div class="panel-body">
            <div style="margin-top: 10px;">
                <el-form label-width="80px">
                <el-form-item label="题目排序">
                    <el-input-number v-model="question.sort_num" :min="1" placeholder="题号" style="margin-right: 10px;"></el-input-number>
                </el-form-item>
                <el-form-item label="分数">
                    <el-input-number v-model="question.marks" :min="1" placeholder="分数" style="margin-right: 10px;"></el-input-number>
                </el-form-item>
                <el-form-item label="题目类型">
                    <el-select v-model="question.type" placeholder="选择题目类型" style="margin-right: 10px;">
                    <el-option label="单选题" :value="1"></el-option>
                    <el-option label="多选题" :value="2"></el-option>
                    <el-option label="判断题" :value="3"></el-option>
                    <el-option label="解答题" :value="4"></el-option>
                    </el-select>
                </el-form-item>
                </el-form>
            </div>
            <h4>题目：</h4>
            <ul>
                <li v-for="(question, index) in filteredQuestions" :key="index">
                    <div v-if="question.type === 'Image'">
                        <img :src="question.src" alt="Cropped Image" style="max-width: 100%;" />
                    </div>
                    <div v-else>
                        <textarea v-model="question.text" placeholder="Edit text here..." rows="4" style="width: 100%;"></textarea>
                    </div>
                </li>
            </ul>
            <h4>答案：</h4>
            <ul>
                <li v-for="(answer, index) in filteredAnswers" :key="index">
                    <div v-if="answer.type === 'Image'">
                        <img :src="answer.src" alt="Cropped Image" style="max-width: 100%;" />
                    </div>
                    <div v-else>
                        <textarea v-model="answer.text" placeholder="Edit text here..." rows="4" style="width: 100%;"></textarea>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
        question: {
            sort_num: 1,
            marks: 0,
            type: 1,
        },
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        questions: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        filteredQuestions() {
            return this.questions.filter(question => question.role === 'Question');
        },
        filteredAnswers() {
            return this.questions.filter(answer => answer.role === 'Answer');
        },
    },

    methods: {
        startCropping() {
            this.$emit('start-cropping');
        },
        submitQuestion() {
            this.$emit('submit-question', this.question);
        },
        closePanel() {
            this.$emit('close');
        }
    }
};
</script>
  
<style scoped>
.add-question-panel {
    position: absolute;
    right: 0;
    width: 30%;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
}
  
.panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
  
.panel-body {
    overflow-y: auto;
    max-height: calc(100% - 80px);
}
</style>
  