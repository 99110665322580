<template>
    <el-container class="profile-container">
      <h2 class="title">个人资料</h2>
      <el-form :model="profile" ref="profileForm" label-width="100px" class="profile-form">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="用户名">
              <el-input v-model="profile.user_acct" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户类型">
              <el-input :value="roleName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户ID">
              <el-input v-model="profile.id" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="姓名">
              <el-input v-model="profile.user_name" :disabled="!editing.user_name"></el-input>
              <el-button type="text" @click="toggleEdit('user_name')">修改</el-button>
              <el-button type="primary" @click="updateField('user_name')" v-if="editing.user_name">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生日">
              <el-input v-model="profile.birthday" :disabled="!editing.birthday"></el-input>
              <el-button type="text" @click="toggleEdit('birthday')">修改</el-button>
              <el-button type="primary" @click="updateField('birthday')" v-if="editing.birthday">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别">
              <el-select v-model="profile.sex" :disabled="!editing.sex">
                <el-option :label="'不详'" :value="0"></el-option>
                <el-option :label="'男'" :value="1"></el-option>
                <el-option :label="'女'" :value="2"></el-option>
              </el-select>
              <el-button type="text" @click="toggleEdit('sex')">修改</el-button>
              <el-button type="primary" @click="updateField('sex')" v-if="editing.sex">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="学校">
              <el-input v-model="profile.school" :disabled="!editing.school"></el-input>
              <el-button type="text" @click="toggleEdit('school')">修改</el-button>
              <el-button type="primary" @click="updateField('school')" v-if="editing.school">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="昵称">
              <el-input v-model="profile.nick" :disabled="!editing.nick"></el-input>
              <el-button type="text" @click="toggleEdit('nick')">修改</el-button>
              <el-button type="primary" @click="updateField('nick')" v-if="editing.nick">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年级">
              <el-input v-model="profile.grade" :disabled="!editing.grade"></el-input>
              <el-button type="text" @click="toggleEdit('grade')">修改</el-button>
              <el-button type="primary" @click="updateField('grade')" v-if="editing.grade">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="电话">
              <el-input v-model="profile.phone" :disabled="!editing.phone"></el-input>
              <el-button type="text" @click="toggleEdit('phone')">修改</el-button>
              <el-button type="primary" @click="updateField('phone')" v-if="editing.phone">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电子邮箱">
              <el-input v-model="profile.email" :disabled="!editing.email"></el-input>
              <el-button type="text" @click="toggleEdit('email')">修改</el-button>
              <el-button type="primary" @click="updateField('email')" v-if="editing.email">保存</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="个性签名">
              <el-input v-model="profile.motto" :disabled="!editing.motto"></el-input>
              <el-button type="text" @click="toggleEdit('motto')">修改</el-button>
              <el-button type="primary" @click="updateField('motto')" v-if="editing.motto">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="修改密码">
              <el-input
                v-if="editing.password"
                type="password"
                v-model="passwords.oldPassword"
                placeholder="输入旧密码">
              </el-input>
              <el-input
                v-if="editing.password"
                type="password"
                v-model="passwords.newPassword"
                placeholder="输入新密码"
                style="margin-top: 10px;">
              </el-input>
              <el-button type="text" @click="toggleEdit('password')">
                {{ editing.password ? '取消' : '修改密码' }}
              </el-button>
              <el-button v-if="editing.password" type="primary" @click="updatePassword">
                保存密码
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-container>
</template>
  
<script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        profile: {
          user_acct: '',
          user_name: '',
          birthday: '',
          sex: '',
          school: '',
          nick: '',
          grade: '',
          phone: '',
          email: '',
          motto: '',
        },
        passwords: {
          oldPassword: '',
          newPassword: ''
        },
        editing: {
          user_name: false,
          birthday: false,
          sex: false,
          school: false,
          nick: false,
          grade: false,
          phone: false,
          email: false,
          motto: false,
          password: false
        }
      };
    },
    created() {
      this.fetchProfile();
    },
    computed: {
    roleName() {
      switch (this.profile.role_id) {
        case 0: return '学生';
        case 1: return '教师';
        case 9: return '管理员';
        default: return '未知身份';
      }
    },
    sexLabel() {
      switch (this.profile.sex) {
        case 0: return '不详';
        case 1: return '男';
        case 2: return '女';
        default: return '不详';
      }
    }
  },
    methods: {
        async fetchProfile() {
            try {
            const accessToken = localStorage.getItem('token');
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/profile`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`  // Include the token in the Authorization header
                }
            });
            this.profile = response.data;
            } catch (error) {
            console.error('Failed to fetch profile:', error);
            }
        },
        toggleEdit(field) {
            this.editing[field] = !this.editing[field];
        },
        async updateField(field) {
            try {
                const updateData = {};
                updateData[field] = this.profile[field];

                await axios.put(`${process.env.VUE_APP_API_URL}/user/update_profile/${field}`, updateData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                this.$message.success(`${field} 更新成功`);
                this.toggleEdit(field);
            } catch (error) {
                console.error(`Failed to update ${field}:`, error);
                this.$message.error(`${field} 更新失败`);
                if (field === 'birthday') {
                    // Validate the date format
                    const regex = /^\d{4}-\d{2}-\d{2}$/;
                    if (!regex.test(this.profile.birthday)) {
                        this.$message.error('生日无效，请填正确的日期。(YYYY-MM-DD 格式)');
                    }
                }
            }
        },                                
        async updatePassword() {
            if (!this.passwords.oldPassword || !this.passwords.newPassword) {
                return this.$message.error('请输入旧密码和新密码');
            }
            if (this.passwords.newPassword.length < 6 || this.passwords.newPassword.length > 20) {
                return this.$message.error('密码长度必须在6到20个字符之间');
            }

            try {             
                await axios.put( `${process.env.VUE_APP_API_URL}/user/update_password`, {
                        oldPassword: this.passwords.oldPassword,
                        newPassword: this.passwords.newPassword
                    }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                this.$message.success('密码更新成功');
                this.toggleEdit('password');
                this.logoutUser();
            } catch (error) {
                console.error('Failed to update password:', error);
                this.$message.error('密码更新失败');
            } finally {
                this.passwords.oldPassword = '';
                this.passwords.newPassword = '';
            }
        },
        logoutUser() {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            this.$router.push('/login');
        }
    }
  };
</script>
  
<style scoped>
.profile-container {
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.el-button {
  margin-left: 10px;
}

.el-form-item {
  margin-bottom: 20px;
}

.profile-form {
  width: 100%;
}
</style>