<template>
  <div class="outer-container">
    <el-container class="register-page-container">
      <el-main class="flex-main">
        <div class="register-container">
          <h2>用户注册</h2>
          <el-form @submit.prevent="handleRegister" :model="form" class="register-form">
            <el-form-item label="用户名" required>
              <el-input type="text" v-model="form.user_acct" required></el-input>
            </el-form-item>
            <el-form-item label="密码" required>
              <el-input type="password" v-model="form.password" required></el-input>
            </el-form-item>
            <el-form-item label="您是" required>
              <el-select v-model="form.role_id" placeholder="请选择角色" required>
                <el-option label="学生" value="0"></el-option>
                <el-option label="教师" value="1"></el-option>
                <el-option label="管理员" value="9"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" native-type="submit">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        user_acct: '',
        password: '',
        role_id: ''
      }
    };
  },
  methods: {
    async handleRegister() {
      if (!this.form.user_acct || !this.form.password || !this.form.role_id) {
        this.$message.error('所有必填项都必须填写');
        return;
      }
      
      // Check password length
      if (this.form.password.length < 6 || this.form.password.length > 20) {
        this.$message.error('密码长度必须在6到20个字符之间');
        return;
      }
      
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/auth/register`, this.form);
        this.$router.push('/login'); // Redirect to login after successful registration
        this.$message.success('注册成功，请联系管理员通过！');
      } catch (error) {
        console.error('Registration failed:', error);
        this.$message.error('注册失败，请重试。');
      }
    }
  }
};
</script>

<style scoped>
.register-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.register-container {
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.el-header h2 {
  margin: 0;
  font-size: 24px;
  text-align: center;
  color: #333;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  width: 100%;
}
.flex-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
