import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import axios from 'axios';

axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        window.alert('No token or expired token. You will be redirected to the login page.');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        router.push('/login');
      }
      return Promise.reject(error);
    }
);

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.mount('#app');

