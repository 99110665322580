<template>
  <div class="user-management-container">
    <h2 class="title">用户管理</h2>
    <div class="controls">
      <el-select v-model="selectedStatus" placeholder="选择用户状态" @change="fetchUsersByStatus" class="status-select">
        <el-option label="等待通过" :value="2"></el-option>
        <el-option label="已通过" :value="1"></el-option>
        <el-option label="已删除" :value="0"></el-option>
      </el-select>
    </div>
    <el-table :data="users" stripe class="user-table">
      <el-table-column prop="user_acct" label="用户名" width="180"></el-table-column>
      <el-table-column label="身份" width="250">
        <template v-slot="scope">
          <span>{{ getRoleLabel(scope.row.role_id) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="用户状态" width="150">
        <template v-slot="scope">
          <el-tag v-if="scope.row.state === 2" type="warning">等待通过</el-tag>
          <el-tag v-else-if="scope.row.state === 1" type="success">已通过</el-tag>
          <el-tag v-else type="danger">删除</el-tag>
        </template>
      </el-table-column>
      

      <el-table-column label="操作">
        <template v-slot="scope">
          <div style="display: flex; gap: 10px;">
            <el-button
              v-if="scope.row.state === 2"
              @click="updateUserState(scope.row, 1)"
              type="primary"
            >
              点击通过
            </el-button>
            <el-button
              v-if="scope.row.state === 2"
              @click="updateUserState(scope.row, 0)"
              type="danger"
            >
              拒绝
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              @click="updateUserState(scope.row, 0)"
              type="danger"
            >
              删除账号
            </el-button>
            <el-button
              v-if="scope.row.state === 0"
              @click="updateUserState(scope.row, 1)"
              type="success"
            >
              恢复账号
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              @click="togglePasswordForm(scope.row.id)"
              type="warning"
            >
              修改密码
            </el-button>
          </div>
          <div v-if="passwordFormVisible && editingUserId === scope.row.id">
            <el-form :model="passwordForm" label-width="100px" @submit.prevent="changePassword">
              <el-form-item label="新密码" required>
                <el-input
                  type="password"
                  v-model="passwordForm.newPassword"
                  required
                />
              </el-form-item>
              <el-button type="primary" @click="changePassword">保存</el-button>
            </el-form>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        users: [],
        selectedStatus: 2, // Default to 'Pending'
        passwordFormVisible: false, // Control visibility of the password form
        editingUserId: null,
        passwordForm: {
          newPassword: '' // Initialize the newPassword field
        }
      };
    },
    created() {
      this.fetchUsersByStatus();
    },
    methods: {
      togglePasswordForm(userId) {
        if (this.editingUserId === userId) {
          this.passwordFormVisible = !this.passwordFormVisible;
        } else {
          this.passwordFormVisible = true;
          this.editingUserId = userId;
          this.passwordForm.newPassword = ''; // Clear the password field when opening the form
        }
      },
      async changePassword() {
        if (!this.passwordForm.newPassword || this.passwordForm.newPassword.length < 6 || this.passwordForm.newPassword.length > 20) {
          this.$message.error('密码长度必须在6到20个字符之间');
          return;
        }

        try {
          const accessToken = localStorage.getItem('token');
          await axios.put(`${process.env.VUE_APP_API_URL}/admin/users/${this.editingUserId}/password`, {
            newPassword: this.passwordForm.newPassword
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          this.$message.success('密码更新成功');
          this.passwordFormVisible = false; // Hide the form after successful update
        } catch (error) {
          console.error('Failed to update password:', error);
          this.$message.error('Failed to update password');
        }
      },
      async fetchUsersByStatus() {
        try {
          const accessToken = localStorage.getItem('token');  // Get the token from localStorage
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/admin/users?status=${this.selectedStatus}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`  // Include the token in the Authorization header
            }
          });
          this.users = response.data;
        } catch (error) {
          console.error('Failed to fetch users:', error);
          this.$message.error('Failed to fetch users');
        }
      },
      
      async updateUserState(user, targetState) {
        try {
          const accessToken = localStorage.getItem('token');  // Get the token from localStorage
          await axios.put(`${process.env.VUE_APP_API_URL}/admin/users/${user.id}`, { state: targetState }, {
            headers: {
              Authorization: `Bearer ${accessToken}`  // Include the token in the Authorization header
            }
          });
          this.fetchUsersByStatus(); // Refresh the list after the update
          this.$message.success(`User ${targetState === 1 ? 'approved' : 'updated'} successfully`);
        } catch (error) {
          console.error('Failed to update user state:', error);
          this.$message.error('Failed to update user state');
        }
      },
      getRoleLabel(role_id) {
        switch (role_id) {
          case 0: return '学生';
          case 1: return '教师';
          case 9: return '管理员';
          default: return '未知身份';
        }
      }
    }

  };
</script>
<style scoped>
.user-management-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.el-select {
  width: 150px;
}

.user-table {
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.el-tag {
  font-size: 14px;
}

.el-button {
  font-size: 14px;
}
</style>
