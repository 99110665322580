<template>
  <el-container style="height: 100vh;">
    <el-aside width="170px" class="sidebar-container">
      <div class="sidebar-title">老师面板</div>
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="4" @click="navigateTo('upload-papers')">
          <i class="el-icon-setting"></i>
          <span>上传试卷</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    
    <!-- Main Content Section -->
    <el-main>
      <div class="header">
        <h2 class="header-title">欢迎来到老师面板</h2>
      </div>
      <router-view></router-view> <!-- This will render the corresponding content based on the route -->
    </el-main>
  </el-container>
</template>
  
<script>
  export default {
    name: 'TeacherDashboard',
    computed: {
      role() {
        return localStorage.getItem('role');
      }
    },
    methods: {
      navigateTo(route) {
        this.$router.push({ path: `/${route}` });
      }
    }
  };
</script>

<style scoped>
/* Layout and Styling */
.el-container {
  height: 100vh;
  padding: 0px;
}

.el-aside {
  background-color: #333; /* Sidebar background color */
  color: #fff; /* Sidebar text color */
  padding: 0px;
}

.sidebar-container {
  height: 100%;
  padding: 0px;
}

.sidebar-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  padding: 16px;
  text-align: center;
  background-color: #333;
  display: flex;
  flex-direction: column;
}

.el-menu-vertical-demo {
  height: calc(100% - 50px); /* Adjust height based on title */
  border-right: none;
  padding: 0px;
}
</style>